import React, { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const images = [
    'url(/image1.jpg)',
    'url(/image2.jpg)',
    'url(/image3.jpg)',
    // Add more image URLs as needed
  ];

  return (
    <div className="App">
      {images.map((image, index) => (
        <div
          key={index}
          className="background-image"
          style={{
            backgroundImage: image,
            opacity: Math.max(0, Math.min(1, 1 - Math.abs(scrollPosition / window.innerHeight - index))),
          }}
        />
      ))}
      <div className="content">
        {/* You can add other content here if needed */}
      </div>
    </div>
  );
}

export default App;